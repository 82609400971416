<template>
    <div>
      <canvas ref="canvas" :id="id"></canvas>
    </div>
</template>  

<script>
import { defineComponent } from 'vue'
import { Bar } from 'vue3-chart-v2'

export default defineComponent({
  name: 'foods-chart',
  extends: Bar,
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    chartId: { 
      type: String,
      default() {
        return "foodChart"
      }
    },    
    labels: {
      type: Array,
      default() {
        return [];
      },
    },
    topValue: {
      type: Number,  
      default() {
        return 5;
      }    
    }    
  },    
  data (props, context) {
    return {
      id: props.chartId,     
      chartOptions: {
        title: {
          display: false,
        },     
        responsive: false, 
        maintainAspectRatio: false,
        aspectRatio: 2,       
        legend: {
          display: false
        },
        gridLines: {
          display: false
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 15,
            bottom: 0
          }
        },
        tooltips: {enabled: false},
        hover: {mode: null},        
        scales: {
          xAxes: [{
            gridLines: {
                display: false,
            },
            ticks: {
              display: true,
              beginAtZero: true,
            },
          }],
          yAxes: [{
            gridLines: {
                display: false
            },
            ticks: {
              display: false,
              beginAtZero: true,
              min : 0,                             
            },
          }]
        },
        animation: {
          onComplete: function () {
            const chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = "rgba(0, 0, 0, 1)";
            ctx.textBaseline = 'bottom';

            // Loop through each data in the datasets

            this.data.datasets.forEach(function (dataset, i) {
                const meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    const data = dataset.data[index];
                    ctx.fillText(data, bar._model.x, bar._model.y - 3);
                });
            });
          }
        }        
      }
    }
  },  
  computed: {
    dataChart: function() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: '',
            data: this.data,
            fill: false,
            borderColor: '#D46827',
            backgroundColor: ["", "", "", "", "", "", ""],
            borderWidth: 0,
            barThickness: 4,          
          }
        ]
      }
    }
  },
  methods: {
    render: function() {
      for (let i = 0; i < 7; i++) {
        const val = this.dataChart.datasets[0].data[i] / this.topValue * 100;

        if(val <= 40)
          this.dataChart.datasets[0].backgroundColor[i] = "#E23513";

        if(val > 40 && val <= 50)
          this.dataChart.datasets[0].backgroundColor[i] = "#D46827";      

        if(val > 50 && val <= 80)
          this.dataChart.datasets[0].backgroundColor[i] = "#D89C29";

        if(val > 80)
          this.dataChart.datasets[0].backgroundColor[i] = "#4E833C";            
      }      
      this.renderChart(this.dataChart, this.chartOptions)
    }
  },
  watch: {
    data: {
      deep: true,              
      handler(newVal, oldVal) {
        const newValue = JSON.stringify(newVal);
        const oldValue = JSON.stringify(oldVal);
        if (newValue !== oldValue) {
          this.render()
        }
      }     
    }  
  },
  mounted () {
    this.render()
  }  
})
</script>

<style scoped>

canvas {
  height: 100px !important;
  width: 200px !important;
  position: absolute;
  top: -30px;
  left: -20px;
}

@media (min-width: 400px) {
  canvas {
    height: 110px !important;
    width: 220px !important;
    top: -35px;
  }
}
@media (min-width: 500px) {
  canvas {
    height: 120px !important;
    width: 240px !important;
    top: -45px;
    left: -10px;
  }
}
@media (min-width: 550px) {
  canvas {
    left: 10px;
  }
}
@media (min-width: 600px) {
  canvas {
    margin-top: 15px;
    height: 100% !important;
    width: 100% !important;
    position: inherit;
  }
}

@media (min-width: 720px) {
  canvas {
    margin-top: 20px;
  }
}

</style>
