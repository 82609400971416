<template>
  <div>
    <canvas id="line-chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  name: 'LineChart',
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    // chartId: {
    //   type: String,
    //   default() {
    //     return "weightChart"
    //   }
    // },
    // animation: {
    //   type: Number,
    //   default() {
    //     return 1000
    //   }
    // },
    labels: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      lineChartData: {
        type: "line",
        data: {
          labels: this.labels,
          datasets: [
            {
              // data: [0, 0, 1, 2, 79, 82, 27, 14],
              data: this.data,
              backgroundColor: 'rgba(212, 104, 39, 0.2)',
              borderColor: "#D46827",
              borderWidth: 2,
              fill: true
            },
            // {
            //   label: "Planetary Mass (relative to the Sun x 10^-6)",
            //   data: [0.166, 2.081, 3.003, 0.323, 954.792, 285.886, 43.662, 51.514],
            //   backgroundColor: "rgba(71, 183,132,.5)",
            //   borderColor: "#47b784",
            //   borderWidth: 3
            // }
          ]
        },
        options: {
          animation:{
            duration: 0
          },
          legend: {
            display: false
          },
          tooltips: {enabled: false},
          hover: {mode: null},
          elements: {
            point:{
              radius: 0
            }
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: true,
                color: '#FFFFFF',
                zeroLineColor: '#FFFFFF'
              },
              ticks: {
                display: true,
              },
            }],
            yAxes: [{
              gridLines: {
                display: false,
                color: '#FFFFFF',
              },
              ticks: {
                display: true,
              },
            }],
          }
          // responsive: true,
          // lineTension: 1,
          // scales: {
          //   yAxes: [
          //     {
          //       ticks: {
          //         //beginAtZero: true,
          //         padding: 25
          //       }
          //     }
          //   ]
          // }
        }
      }
    }
  },
  mounted() {
    const ctx = document.getElementById('line-chart');
    new Chart(ctx, this.lineChartData);
  }
}
</script>