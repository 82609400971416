<template>
    <div class="">
      <canvas ref="canvas" :id="id"></canvas>
    </div>
</template>  

<script>
import { defineComponent } from 'vue'
import { Line } from 'vue3-chart-v2'

export default defineComponent({
  name: 'weight-chart',
  extends: Line,
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },  
    chartId: { 
      type: String,
      default() {
        return "weightChart"
      }
    },
    animation: {
      type: Number,
      default() {
        return 1000
      }      
    },
    labels: {
      type: Array,
      default() {
        return []
      }
    }
  },  
  data (props, context) {
    return {             
      id: props.chartId,
      chartOptions: {
        chartId: props.chartId,
        animation: {
          duration: props.animation
        },        
        title: {
          display: false,
        },             
        legend: {
          display: false
        },        
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        tooltips: {enabled: false},
        hover: {mode: null},                
        elements: {
          point:{
            radius: 0
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
                display: true,
                color: '#FFFFFF',
                zeroLineColor: '#FFFFFF'
            },
            ticks: {
              display: true,
            }, 
          }],
          yAxes: [{          
            gridLines: {
                display: false,
                color: '#FFFFFF',
            },
            ticks: {
              display: true,
            },            
          }],
        }                           
      }        
    }
  },
  computed: {
    dataChart: function() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: '',
            data: this.data,
            fill: true,
            borderColor: '#D46827',
            backgroundColor: 'rgba(212, 104, 39, 0.2)',
            borderWidth: 2
          }
        ]
      }
    }
  },
  methods: {
    render: function() {
      this.renderChart(this.dataChart, this.chartOptions)
    }
  },
  watch: {
    data: {
      deep: true,              
      handler(newVal, oldVal) {
        this.render()
      }     
    }  
  },
  mounted () {
    this.render()
  }
})
</script>
