<template>
  <ion-header>
    <ion-toolbar mode="md">
      <div slot="start" >
        <img src="assets/icon/dash-logo.svg" style="margin-top: 10px"/>
      </div>
      <ion-buttons slot="end" style="margin-right: 15px">
        <ion-icon
          class="config"
          v-on:click="goToConfig()"
          :icon="settingsOutline"
          style="font-size: 25px"
        ></ion-icon
        >&nbsp;&nbsp;&nbsp;
        <div class="button">
          <ion-icon
            v-on:click="goToNoti()" 
            :icon="notificationsOutline" >
          </ion-icon>
          <ion-badge 
            v-if="countNotifications > 0"
            class="badge" 
            color="danger">
            {{ countNotifications }}
          </ion-badge>  
        </div>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import {
  IonToolbar,
  IonIcon,
  
  
  IonHeader,
  
  IonBadge
} from "@ionic/vue";
import { settingsOutline, notificationsOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import router from "../router/index";
import { useStore } from "vuex";

export default defineComponent({
  name: "LoggedInNavDash",
  components: {
    IonIcon,
    IonToolbar,
    IonBadge,
    IonHeader
  },
  data() {
    return { subcategoria: false };
  },
  computed: {
    countNotifications() {
      const list = this.$store.getters["Notification/getNotifications"];
      return list.length;
    }, 
  },
  methods: {
    goToConfig() {
      router.push("/configuracion");
    },
    goToNoti() {
      router.push("/notificaciones");
    },
  },
  setup() {
    const store = useStore();
    return {
      settingsOutline,
      notificationsOutline,
      store
    };
  },
});
</script>
<style lang="scss" scoped>

  .button {            
    position: relative;
    top:2px;
    right: 1px;
    overflow: visible!important;
    font-size: 25px;
    cursor: pointer;
  }

  .badge {
    background-color: red;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 100%;
    cursor: pointer;
  }  
  
  .config {
    cursor: pointer;
  }

   /** Ion Toolbar Desktop Styles (No hay flecha y tiene configuracion/noti) **/
  ion-toolbar {
    --padding-start: 2%;
  }
  @media (min-width: 600px) {
    ion-toolbar {
      --padding-end: 1%;
    }
  }
  @media (min-width: 700px) {
    ion-toolbar {
      --padding-end: 2%;
    }
  }
  @media (min-width: 800px) {
    ion-toolbar {
      --padding-start: 4%;
      --padding-end: 4%;
    }
  }
  @media (min-width: 850px) {
    ion-toolbar {
      --padding-start: 5%;
      --padding-end: 5%;
    }
  }
  @media (min-width: 900px) {
    ion-toolbar {
      --padding-start: 10%;
      --padding-end: 10%;
    }
  }
  @media (min-width: 1000px) {
    ion-toolbar {
      --padding-start: 15%;
      --padding-end: 15%;
    }
  }
  @media (min-width: 1200px) {
    ion-toolbar {
      --padding-start: 20%;
      --padding-end: 20%;
    }
  }
  @media (min-width: 1300px) {
    ion-toolbar {
      --padding-start: 22%;
      --padding-end: 22%;
    }
  }
  @media (min-width: 1400px) {
    ion-toolbar {
      --padding-start: 23%;
      --padding-end: 23%;
    }
  }
 @media (min-width: 1500px) {
    ion-toolbar {
      --padding-start: 25%;
      --padding-end: 25%;
    }
  }
  @media (min-width: 1600px) {
    ion-toolbar {
      --padding-start: 27%;
      --padding-end: 27%;
    }
  }
  @media (min-width: 1800px) {
    ion-toolbar {
      --padding-start: 30%;
      --padding-end: 30%;
    }
  }
  @media (min-width: 1900px) {
    ion-toolbar {
      --padding-start: 31%;
      --padding-end: 31%;
    }
  }

</style>
